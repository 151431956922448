import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/certification",
    children: [
      {
        path: "/certifications",
        name: "Certifications",
        component: () =>
          import("@/modules/certification/views/Certifications.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.certification.certifications.meta.title",
          permission: Permission.JobCertification,
        },
      },
      {
        path: ":id/:creatorId?",
        name: "CertificationApproval",
        component: () =>
          import("@/modules/certification/views/CertificationApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.imageProductOnly.meta",
          permission: Permission.JobCertification,
        },
      },
      {
        path: ":certificationId/list",
        name: "PendingCertifications",
        component: () =>
          import("@/modules/certification/views/PendingCertificationList.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.imageProductOnly.meta",
          permission: Permission.JobCertification,
        },
      },
    ],
  },
];
